<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="730">      
      <v-card>
        <v-card-title class="headline">
          <h1>{{ isTicket ? "Novo Ticket / Cota" : "Novo Produto" }}</h1>
          <v-btn class="close-modal" @click="closeModal">
            <i class="fal fa-times"></i>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-flex flex-column>
            <v-layout pa-3>
              <v-flex xs5 pr-3>
                <div class="upload-image" id="preview" ref="preview">
                  <label for="upload-activity">
                    <i class="fas fa-camera-alt"></i>
                  </label>
                  <label
                    for="upload-activity"
                    class="do-upload"
                    v-show="donationProduct.file.path == ''"
                  >
                    <i class="fal fa-cloud-upload"></i>Faça Upload
                  </label>
                  <input
                    type="file"
                    id="upload-activity"
                    accept="image/*"
                    @change="fileChanged"
                    ref="upload_activity"
                  />
                </div>
              </v-flex>
              <!-- TICKET -->
              <template v-if="isTicket">
                <v-flex xs7>
                  <v-layout row wrap>
                    <v-flex xs12>
                      <InputText
                        ref="nameInput"
                        type="text"
                        place-holder="Dê um nome para este produto"
                        textLabel="Nome do produto"
                        v-model="donationProduct.name"
                        invalidInputMessage="Preencha o nome do produto"
                        labelCharLimit="50"
                      />
                    </v-flex>
                    <v-flex xs12>
                      <v-checkbox
                        v-model="donationProduct.openValue"
                        :label="`Produto de valor aberto`"
                        @change="changeOpenValue"
                      ></v-checkbox>
                    </v-flex>
                    <v-flex xs12>
                      <v-checkbox
                        v-model="donationProduct.openTickets"
                        :label="`Produto com tickets ilimitados`"
                        @change="changeOpenTickets"
                      ></v-checkbox>
                    </v-flex>
                    <v-flex xs6 pr-2 v-if="showValue">
                      <InputMoney
                        ref="fixedValueInput"
                        type="tel"
                        v-model="donationProduct.fixedValue"
                        :precision="2"
                        textLabel="Valor"
                        :infoVisible="false"
                        invalidInputMessage="Preencha o valor"
                      />
                    </v-flex>
                    <v-flex xs6 v-if="showTicketsQtde">
                      <InputText
                        ref="availableTicketsQuantityInput"
                        v-model.number="donationProduct.availableTicketsQuantity"
                        type="tel"
                        place-holder="340"
                        textLabel="Ingressos disponíveis"
                        invalidInputMessage="Preencha a quantidade disponível"
                        mask="#########"
                      />
                    </v-flex>
                    <v-flex xs12>
                      <InputDateRange
                        ref="initialFinalDateInput"
                        textLabel="Data/Período"
                        place-holder="Selecione"
                        v-model="donationProduct.initialFinalDates"
                        :monthsToShow="3"
                        :allowSingleDate="true"
                        invalidInputMessage="Preencha o intervalo de datas"
                      ></InputDateRange>
                    </v-flex>
                    <div class="txtarea-labels input-comp">
                      <label>Observações</label>
                      <InputTextArea
                        ref="descriptionInput"
                        rows="2"
                        v-model="donationProduct.description"
                        place-holder="Escreva sobre esta atividade do projeto"
                        invalidInputMessage="Preencha a descrição da faixa"
                        labelAreaCharLimit="1000"
                      ></InputTextArea>
                    </div>
                    <div class="txtarea-labels input-comp">
                      <label>Regras e Condições</label>
                      <InputTextArea
                        ref="rulesAndConditionsInput"
                        rows="2"
                        v-model="donationProduct.rulesAndConditions"
                        place-holder="Escreva sobre as regras e condições"
                        invalidInputMessage="Preencha as regras e condições"
                      ></InputTextArea>
                    </div>
                    <div class="txtarea-labels input-comp">
                      <label>
                        Url Customizada
                        <br />
                        Exemplo (logado): {{ "*.abraceumacausa.com.br/carrinho?product=" + donationProduct.customUrl }}<br>
                        Exemplo (simplificado): {{ "*.abraceumacausa.com.br?campanha=" + donationProduct.customUrl }}
                      </label>
                      <InputTextArea
                        rows="1"
                        :maxlength="40"
                        v-model="donationProduct.customUrl"
                        place-holder="Valor para a url customizada sem espaços em branco"
                        invalidInputMessage="Valor para a url customizada"
                      ></InputTextArea>
                    </div>
                  </v-layout>
                  <div class="txtarea-labels input-comp"></div>
                </v-flex>
              </template>
              <!-- PRODUTO DE DOAÇÃO -->
              <template v-else>
                <v-flex xs7>
                  <InputText
                    ref="nameInput"
                    v-model="donationProduct.name"
                    type="text"
                    place-holder="Dê um nome para este produto"
                    textLabel="Nome do produto"
                    invalidInputMessage="Preencha o nome do produto"
                    labelCharLimit="50"
                  />
                  <v-layout>
                    <v-flex xs12 pr-2>
                      <InputText
                        ref="suggestionValueInput"
                        v-model="donationProduct.suggestionValue"
                        type="text"
                        place-holder="Dê um valor para este produto"
                        textLabel="Valor sugerido (Coloque R$ antes de valores) - Opcional"
                        helpLabel="Ex: de R$20 a R$40"
                        :required="false"
                      />
                    </v-flex>
                  </v-layout>
                  <div class="txtarea-labels input-comp">
                    <label>Descrição</label>
                    <InputTextArea
                      ref="descriptionInput"
                      v-model="donationProduct.description"
                      rows="2"
                      invalidInputMessage="Preencha a descrição da faixa"
                      labelAreaCharLimit="250"
                    ></InputTextArea>
                  </div>
                  <div class="txtarea-labels input-comp">
                    <label>
                      Url Customizada
                      <br />
                      Exemplo: {{ "*.abraceumacausa.com.br/carrinho?product=" + donationProduct.customUrl }}
                    </label>
                    <InputTextArea
                      rows="1"
                      :maxlength="40"
                      v-model="donationProduct.customUrl"
                      place-holder="Valor para a url customizada sem espaços em branco"
                      invalidInputMessage="Valor para a url customizada"
                    ></InputTextArea>
                  </div>
                </v-flex>
              </template>
            </v-layout>
            <v-layout pa-3 d-flex flex-column>
              <v-layout xs12 md12 align-center>
                <v-flex xs1>
                  <v-switch label v-model="donationProduct.showQuestions" class="ma-0"></v-switch>
                </v-flex>
                <h3 class="title-category">Especificações</h3>
              </v-layout>
              <transition name="slide-fade" mode="out-in">
                <v-layout d-flex flex-column v-if="donationProduct.showQuestions">
                  <div
                    v-for="(donationProductQuestion, index) in donationProduct.donationProductQuestionList.filter((x) => x.active == true)"
                    :key="donationProductQuestion.Order"
                  >
                    <v-layout xs12 pr-2 align-center>
                      <!-- <v-flex xs6> -->
                      <v-flex xs11>
                        <InputText
                          ref="questionInput"
                          v-model="donationProductQuestion.question"
                          type="text"
                          place-holder="Etiqueta da especificação"
                          textLabel="Etiqueta"
                          :required="false"
                        />
                      </v-flex>
                      <!-- <v-flex xs5 pl-2>
                        <InputSelect
                          :items="answerType"
                          textLabel="Tipo de resposta"
                          valueAttribute="id"
                          textAttribute="name"
                        ></InputSelect>
                      </v-flex>-->
                      <v-flex xs1 pt-4>
                        <a
                          @click="addQuestion()"
                          v-if="index == donationProduct.donationProductQuestionList.length - 1"
                        >
                          <Icon
                            name="fal fa-plus-circle justify-end d-flex"
                            size="24"
                            class="ml-2"
                            colorFont="#C3C6D5"
                          />
                        </a>
                        <a @click="removeQuestion(index)" v-else>
                          <Icon
                            name="fal fa-trash justify-end d-flex"
                            size="24"
                            class="ml-2"
                            colorFont="#C3C6D5"
                          />
                        </a>
                      </v-flex>
                    </v-layout>
                    <v-layout xs12 md12 align-center>
                      <v-switch
                        label="Alternativas"
                        v-model="donationProductQuestion.hasAlternatives"
                        class="ma-0"
                        @change="changeSwitchAlternatives(donationProductQuestion)"
                      ></v-switch>
                    </v-layout>
                    <v-layout pr-4 flex-wrap v-if="donationProductQuestion.hasAlternatives">
                      <v-layout
                        v-for="(alternative, index) in donationProductQuestion.donationProductQuestionAlternatives"
                        :key="alternative.id"
                        xs12
                        md6
                        lg4
                        pr-2
                        align-center
                        class="flex"
                      >
                        <v-flex xs10>
                          <InputText
                            ref="alternativeInput"
                            v-model="alternative.description"
                            type="text"
                            place-holder="Etiqueta da especificação"
                            textLabel="Etiqueta"
                            :required="false"
                          />
                        </v-flex>
                        <v-flex xs2 pt-4>
                          <a
                            v-if="index == donationProductQuestion.donationProductQuestionAlternatives.length - 1"
                            @click="addAlternative(donationProductQuestion)"
                          >
                            <Icon
                              name="fal fa-plus-circle justify-center d-flex"
                              size="20"
                              colorFont="#C3C6D5"
                            />
                          </a>
                          <a v-else @click="removeAlternative(index, donationProductQuestion)">
                            <Icon
                              name="fal fa-trash justify-center d-flex"
                              size="20"
                              colorFont="#C3C6D5"
                            />
                          </a>
                        </v-flex>
                      </v-layout>
                    </v-layout>
                  </div>
                  <!-- <v-layout xs12 pr-2 align-center>
                    <v-flex xs2>
                      <InputText
                        type="text"
                        place-holder="Ex: P"
                        textLabel="Opções"
                        :required="false"
                      />
                    </v-flex>
                    <v-flex xs2>
                      <InputText
                        type="text"
                        :required="false"
                        class="pl-2"
                        style="padding-top: 20px"
                      />
                    </v-flex>
                    <v-flex xs2>
                      <InputText
                        type="text"
                        :required="false"
                        class="pl-2"
                        style="padding-top: 20px"
                      />
                    </v-flex>
                    <v-flex xs2>
                      <InputText
                        type="text"
                        :required="false"
                        class="pl-2"
                        style="padding-top: 20px"
                      />
                    </v-flex>
                    <v-flex xs2>
                      <InputText
                        type="text"
                        :required="false"
                        class="pl-2"
                        style="padding-top: 20px"
                      />
                    </v-flex>
                    <v-flex xs2>
                      <InputText
                        type="text"
                        :required="false"
                        class="pl-2"
                        style="padding-top: 20px"
                      />
                    </v-flex>
                    <v-flex xs2>
                      <InputText
                        type="text"
                        :required="false"
                        class="pl-2"
                        style="padding-top: 20px"
                      />
                    </v-flex>
                  </v-layout>-->
                </v-layout>
              </transition>
            </v-layout>
          </v-flex>
        </v-card-text>
        <v-card-actions class="pa-0">
          <div class="footer-modal-atividades">
            <v-btn
              flat
              round
              color="white"
              class="btn btn-default px-5"
              large
              @click="closeModal"
            >Cancelar</v-btn>
            <v-btn
              flat
              round
              color="white"
              class="btn btn-primary px-5"
              large
              @click="isValid"
            >Salvar</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script type="plain/text">
import DonationProduct from "@/scripts/models/donationProduct.model";
import DonationProductQuestion from "@/scripts/models/donationProductQuestion.model";
import DonationProductQuestionAlternatives from "@/scripts/models/donationProductQuestionAlternatives.model";
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
import { GetCurrentOrder } from "@/scripts/helpers/list.helper";
import ProjectService from "@/scripts/services/project.service";
import CommonHelper from "@/scripts/helpers/common.helper";
import { format } from "date-fns";
export default {
  extends: ValidatableComponent,
  props: ["projectId", "isTicket"],
  data() {
    return {
      CommonHelper: CommonHelper,
      dialog: false,
      showValue: true,
      showTicketsQtde: true,
      projectService: new ProjectService(),
      donationProduct: new DonationProduct(),
      donationProductList: this.value,
      originalDonationProduct: null,
      validCustomUrl: true,
      answerType: [
        {
          id: 1,
          name: "Checkbox",
        },
        {
          id: 2,
          name: "Radio",
        },
      ],
    };
  },
  watch: {
    "donationProduct.showQuestions": {
      handler: function (after, before) {
        if (after && this.donationProduct.donationProductQuestionList.length == 0) {
          let donationProductQuestion = new DonationProductQuestion();

          donationProductQuestion.donationProductQuestionAlternatives.push(new DonationProductQuestionAlternatives());

          this.donationProduct.donationProductQuestionList.push(donationProductQuestion);
        }
      },
      deep: true,
    },
  },
  created() {
    this.onValidationSuccessMethod = this.validationSuccess;
    this.validationMethod = this.validate;
  },
  methods: {
    changeOpenValue(value) {
      this.showValue = !value;
      if (!value) this.donationProduct.fixedValue = 0;
    },
    changeOpenTickets(value) {
      this.showTicketsQtde = !value;
      if (!value) this.donationProduct.availableTicketsQuantity = 0;
    },
    listByProjectId() {
      this.projectService.listDonationProductByProjectId(this.projectId).then(data => this.$emit("input", data));
    },
    save() {
      if (this.donationProduct.customUrl != null) {
        if (this.donationProduct.customUrl.indexOf(' ') >= 0) {
          this.CommonHelper.swal("A URL customizada não pode conter espaços em branco");
          this.validCustomUrl = false;
          return;
        }
      }
      this.validCustomUrl = true;
      this.originalDonationProduct.projectId = this.projectId;
      this.projectService.saveDonationProduct(this.refreshList, this.originalDonationProduct);
    },
    changeStatus(donationProduct, current) {
      donationProduct.projectId = this.projectId;
      donationProduct.active = current;
      this.projectService.saveDonationProduct(this.refreshList, donationProduct);
    },
    remove(donationProductId) {
      this.projectService.deleteDonationProduct(this.refreshList, donationProductId);
    },
    refreshList() {
      this.listByProjectId();
    },
    changeSwitchAlternatives(donationProductQuestion) {
      if (donationProductQuestion.hasAlternatives && donationProductQuestion.donationProductQuestionAlternatives.length == 0) {
        let newAlternative = new DonationProductQuestionAlternatives();
        donationProductQuestion.donationProductQuestionAlternatives.push(newAlternative);
      }
    },
    addAlternative(donationProductQuestion) {
      let valid = true;

      for (let i = 0; i < donationProductQuestion.donationProductQuestionAlternatives.length; i++) {
        if (!donationProductQuestion.donationProductQuestionAlternatives[i].description) {
          this.$refs.alternativeInput[i].focus();
          valid = false;
        }
      }
      if (valid) {
        let newAlternative = new DonationProductQuestionAlternatives();
        donationProductQuestion.donationProductQuestionAlternatives.push(newAlternative);
      }
    },
    removeAlternative(index, donationProductQuestion) {
      donationProductQuestion.donationProductQuestionAlternatives.splice(index, 1);
    },
    addQuestion() {
      let valid = true;

      for (let i = 0; i < this.donationProduct.donationProductQuestionList.length; i++) {
        if (!this.donationProduct.donationProductQuestionList[i].question) {
          this.$refs.questionInput[i].focus();
          valid = false;
        }
      }

      if (valid) {
        let newQuestion = new DonationProductQuestion();
        newQuestion.Order = GetCurrentOrder(this.donationProduct.donationProductQuestionList);
        newQuestion.donationProductQuestionAlternatives.push(new DonationProductQuestionAlternatives());
        this.donationProduct.donationProductQuestionList.push(newQuestion);
      }
    },
    removeQuestion(index) {
      this.donationProduct.donationProductQuestionList.splice(index, 1);
    },
    validate(performMethods) {      
      if (this.donationProduct.file == null || this.donationProduct.file.path == "") {
        this.$store.commit("SET_SNACKBAR_MESSAGE", "Escolha uma imagem para a faixa de valores");
        return false;
      }

      let valid = this.validateComponents(performMethods);
      if (!valid) return false;
      if (this.donationProduct.showQuestions) {
        for (let i = 0; i < this.donationProduct.donationProductQuestionList.length; i++) {
          if (!this.donationProduct.donationProductQuestionList[i].question) {
            if (this.donationProduct.donationProductQuestionList.length == 1) {
              this.$refs.questionInput[i].focus();
              valid = false;
            } else if (i == this.donationProduct.donationProductQuestionList.length - 1) {
              this.removeQuestion(i);
              valid = true;
            } else {
              this.$refs.questionInput[i].focus();
              valid = false;
            }
          }
        }
      }
      return valid;
    },
    validationSuccess() {
      this.copy(this.donationProduct, this.originalDonationProduct);
      this.save();
      if (this.validCustomUrl) {
        this.closeModal();
      }
    },
    closeModal() {
      this.dialog = false;
      this.copy(new DonationProduct(), this.donationProduct);
      this.$refs.preview.style.backgroundImage = "";
    },
    fileChanged(event) {
      var file = event.target.files[0];
      if (file) {
        let reader = new FileReader();
        this.donationProduct.file.name = file.name;
        reader.onload = function () {
          this.$refs.preview.style.backgroundImage = "url(" + reader.result + ")";
          this.donationProduct.file.path = reader.result;
        }.bind(this);
        reader.readAsDataURL(file);
      }
    },
    open(item) {      
      this.dialog = true;
      this.originalDonationProduct = item;
      this.copy(this.originalDonationProduct, this.donationProduct);
      this.showValue = !this.donationProduct.openValue;
      this.showTicketsQtde = !this.donationProduct.openTickets;
      this.$refs.preview.style.backgroundImage = "url(" + this.donationProduct.file.path + ")";
      this.$refs.nameInput.showValidationMessage = false;
      this.$refs.descriptionInput.showValidationMessage = false;
      if (this.isTicket) {
        this.$refs.fixedValueInput.showValidationMessage = false;
        this.$refs.availableTicketsQuantityInput.showValidationMessage = false;
        this.$refs.initialFinalDateInput.showValidationMessage = false;
        this.$refs.rulesAndConditionsInput.showValidationMessage = false;
        this.$refs.customUrlInput.showValidationMessage = false;
      } else {
        this.$refs.suggestionValueInput.showValidationMessage = false;
      }
    },
    copy(from, to) {
      to.Order = from.Order;
      to.id = from.id;
      to.name = from.name;
      to.suggestionValue = from.suggestionValue;
      to.description = from.description;
      to.showQuestions = from.showQuestions;
      to.donationProductQuestionList = [];
      to.availableTicketsQuantity = from.availableTicketsQuantity;
      to.rulesAndConditions = from.rulesAndConditions;
      to.fixedValue = from.fixedValue;
      to.openValue = from.openValue;
      to.openTickets = from.openTickets;
      to.customUrl = from.customUrl;
      to.initialFinalDates = [];
      if (from.initialFinalDates.length == 2) {
        to.initialDate = from.initialFinalDates[0];
        to.initialFinalDates[0] = from.initialFinalDates[0];
        if (from.initialFinalDates[1]) {
          to.finalDate = from.initialFinalDates[1];
          to.initialFinalDates[1] = from.initialFinalDates[1];
        } else {
          to.finalDate = null;
          to.initialFinalDates[1] = null;
        }
      } else {
        to.initialDate = from.initialFinalDates[0];
        to.initialFinalDates[0] = from.initialFinalDates[0];
      }
      for (let i = 0; i < from.donationProductQuestionList.length; i++) {
        let question = new DonationProductQuestion();
        this.copyQuestion(from.donationProductQuestionList[i], question);
        to.donationProductQuestionList.push(question);
      }
      to.file.id = from.file.id;
      to.file.userId = from.file.userId;
      to.file.name = from.file.name;
      to.file.path = from.file.path;
      to.file.size = from.file.size;
      to.file.extension = from.file.extension;
      to.file.origin = from.file.origin;
    },
    copyQuestion(from, to) {
      to.Order = from.Order;
      to.id = from.id;
      to.donationProductId = from.donationProductId;
      to.question = from.question;
      to.hasAlternatives = from.hasAlternatives;
      to.active = from.active;
      from.donationProductQuestionAlternatives.forEach((fromAlternative) => {
        let alternative = new DonationProductQuestionAlternatives();
        this.copyAlternative(fromAlternative, alternative);
        to.donationProductQuestionAlternatives.push(alternative);
      });
    },
    copyAlternative(from, to) {
      to.id = from.id;
      to.description = from.description;
    },
  },
};
</script>
